import { PaymentFormData, PaymentInstruction } from 'types/paymentInstruction'
import { convertFormAmountToCents } from '.'
import dayjs from 'dayjs'
import { Providers } from 'types/rules'

export default function mapFormToPaymentInstruction(
  formData: PaymentFormData,
  profileId: string,
  dateDue: dayjs.Dayjs
): PaymentInstruction {
  const pi: PaymentInstruction = {
    profileId: profileId,
    beneficiaryId: formData.beneficiaryId!,
    amount: convertFormAmountToCents(formData.amount!),
    currency: formData.currency!,
    reference: [formData.reference!],
    creationChannel: {
      channel: 'web',
    },
    sourceId: formData.sourceId,
    dateDue: dateDue.format('YYYY-MM-DD'),
    remittanceAdvice: formData.remittanceAdvice
      ? {
          emails: formData.remittanceAdvice.split(','),
        }
      : undefined,
  }

  if (formData.vcnAccountName) {
    pi.vcnAccountName = formData.vcnAccountName
  }

  if (formData.vcnAccountNumber) {
    pi.vcnAccountNumber = formData.vcnAccountNumber
  }

  if (formData.vcnProviderProperties) {
    pi.vcnProviderProperties = Object.entries(formData.vcnProviderProperties).map(([key, value]) => ({
      [key]: value,
    }))
    pi.vcnProviderId = Providers.AIRPLUS
  }

  return pi
}
