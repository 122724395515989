import { ClearingNetworkRule } from './beneficiary'
import { Entity } from './entity'
import { Source } from './source'

export interface ClearingNetwork {
  clearingNetwork: string
  countries: string[]
}

export interface AllowedCountryAccountNumberTypes {
  countryCode: string
  iban?: boolean
  bban?: boolean
}

export interface ClearingNetworkCountries {
  clearingNetwork: string
  countries: AllowedCountryAccountNumberTypes[]
}

export interface LanguagePreferences {
  entityEditable: boolean
  derived: string[]
  rule: string
}

export interface TwoFactorAuthenticationRule {
  what: 'sms' | 'mail' | 'call'
  when: 'device' | 'always' | 'never'
}

export interface TwoFactorAuthentication {
  entityEditable: boolean
  derived: string[]
  rule: TwoFactorAuthenticationRule
}

interface Crud {
  derived: string[]
  entityEditable: boolean
  rule: boolean
}

interface ACLAction {
  [create: string]: Crud
  read: Crud
  update: Crud
  delete: Crud
}
interface ACLInner {
  entity: ACLAction
  profile: ACLAction
}
export interface ACL {
  [beneficiary: string]: ACLInner
  charge: ACLInner
  entity: ACLInner
  event: ACLInner
  import: ACLInner
  paymentInstruction: ACLInner
  paymentInstructionTemplate: ACLInner
  profile: ACLInner
  report: ACLInner
  rule: ACLInner
  set: ACLInner
  signature: ACLInner
  source: ACLInner
  token: ACLInner
  user: ACLInner
}
export interface RequiredACL {
  [kind: string]: string
  action: string
}
export interface PaymentGateway {
  cardNetwork: string
  currency: string
  paymentGateway: string
  gatewayAccount: string
  mid: string
}

export interface IntegrationProperties {
  [key: string]: unknown
  connectionId?: string
  organizationName?: string
  feeAccountCode?: string
  organizationShortCode?: string
}

export interface AirplusIntegrationProperties {
  accountNumber: string
  accountName: string
  currency: string
  dbis: {
    [key: string]: {
      required: boolean
    }
  }
}

export interface Integration {
  status: string
  integrationProperties?: IntegrationProperties
}

export interface AirplusIntegration {
  id: string
  status: string
  providerId: string
  integrationProperties: AirplusIntegrationProperties
}

export interface RulesLogic {
  [key: string]: unknown
  paymentGateways: {
    entityEditable?: boolean
    derived?: string[]
    rule: PaymentGateway[]
  }
  cardNetworks: {
    entityEditable: boolean
    derived: string[]
    rule: {
      [key: string]: boolean
      mc: boolean
      visa: boolean
      amex: boolean
      diners: boolean
    }
  }
  availableIntegrations: {
    entityEditable: boolean
    derived: string[]
    rule: {
      [key: string]: boolean
      sage: boolean
      fortnox: boolean
      wayneEnterprisesMainframe: boolean
    }
  }
  activeIntegrations: {
    entityEditable: boolean
    derived: string[]
    rule: {
      [key: string]: Integration | AirplusIntegration[]
    }
  }
  allowedBeneficiaryCountries: {
    entityEditable: boolean
    derived: string[]
    rule: ClearingNetwork[]
  }
  clearingNetworkCountries: {
    entityEditable: boolean
    derived: string[]
    rule: ClearingNetworkCountries[]
  }
  currencies?: {
    derived: string[]
    entityEditable: boolean
    rule: {
      [key: string]: boolean
    }
  }
  clearingNetworks: {
    entityEditable: boolean
    derived: string[]
    rule: Array<ClearingNetworkRule>
  }
  paymentDays: {
    entityEditable: false
    derived: string[]
    rule:
      | {
          cardNetwork: string
          clearingNetwork: string
          currency: string
          amountFrom: number
          amountTo?: number
          days: number
        }[]
      | []
  }
  limitPaymentInstruction: {
    entityEditable: boolean
    derived: string[]
    rule: { currency: string; limit: number }[] | []
  }
  languagePreferences: LanguagePreferences
  notificationPreferences: NotificationPreferences
  twoFactorAuthentication: TwoFactorAuthentication
  profileGroupSignChainRound: {
    entityEditable?: boolean
    derived?: string[]
    rule: { profileGroupId: string; round: number[] }[]
  }
  requiredSignChain: {
    entityEditable: boolean
    derived: string[]
    rule: SignChainRule[]
  }
  authorizationMethods: {
    entityEditable: boolean
    derived: string[]
    rule: {
      credentials?: boolean
      sso?: boolean
    }
  }
  registrationParameters?: {
    entityEditable: false
    derived: string[]
    rule?: {
      documentTypes?: {
        person: {
          [key: string]: string[]
          identity: []
          selfie: []
          address: []
        }
        sme: {
          [key: string]: string[]
          identity: []
          selfie: []
          address: []
          organization: string[]
        }
        corp: {
          [key: string]: string[]
          identity: []
          selfie: []
          address: []
          organization: string[]
        }
      }
      inputParameters?: {
        person?: string[]
        sme?: string[]
        corp?: string[]
      }
      cinHitRequired?: boolean
    }
  }
  registrationCountries?: {
    entityEditable: boolean
    derived: string[]
    rule?: string[]
  }
  passwordPolicy?: {
    entityEditable: boolean
    derived: string[]
    rule: PasswordPolicyRule
  }
  clearingNetworksReferenceLength?: {
    entityEditable: boolean
    derived: string[]
    rule: ClearingNetworksReferenceLengthRule
  }

  supplierFundedRegistry: {
    entityEditable: boolean
    derived: string[]
    rule: SupplierFunded[]
  }
  requiredPIFields: {
    entityEditable: boolean
    derived: string[]
    rule: RequiredPIFields
  }
  availableRoles: AvailableRoles
  cardManagedByAdmin?: boolean
  defaultCurrency?: {
    entityEditable: boolean
    derived: string[]
    rule: string
  }
  featureFlags: FeatureFlags
  showCreatePiFields: {
    derived: string[]
    rule: ShowCreatePiFields
  }
  fileImport: {
    derived: string[]
    entityEditable?: boolean
    rule: FileImportRule
  }
  paymentInstructionReferencePrefixAndSuffix: {
    entityEditable: boolean
    derived: string[]
    rule: {
      prefix: string
      suffix: string
    }
  }
}

export type FileImportRule = {
  dateDuePolicy: string
  importCharge: {
    autoCharge: boolean
  }
  showFileImport?: boolean
}

export type Features = {
  amountOfPaymentsForAsyncResponse: number
  useDynamicPaymentThreshold: boolean
}

type FeatureFlags = {
  derived: string[]
  rule: Features
}

export type ShowCreatePiFields = {
  dateDue: boolean
  attachment: boolean
  recurring: boolean
  remittanceAdvice: boolean
}

interface ToggleRule {
  derived: string[]
  entityEditable: boolean
  rule: boolean
}

export interface ToggleRules {
  alertNewBeneficiary?: ToggleRule
  supplierFunded?: ToggleRule
  supplierFundedSimplified?: ToggleRule
  cardManagedByAdmin?: ToggleRule
  allowPaymentsInForeignCardCurrency?: ToggleRule
  allEntityPaymentsVisible?: ToggleRule
  stopFileImportDuplicates?: ToggleRule
  selectAllApproversParallelFlow?: ToggleRule
  customCardIdByCustomer?: ToggleRule
}

export interface SupplierFunded {
  beneficiaryId?: string
  feePercent: number
  feeFixed: number
  feeFixedCurrency: string
  sourceId?: string
  source?: Source
}

export interface Rules {
  logic: RulesLogic
  toggles: ToggleRules
  acl: ACL
}

export interface SignChainRule {
  currency: string
  amountFrom: number
  requiredSignatures: number
  signatureOrder: 'sequential' | 'parallel'
}

interface NotificationItem {
  mail?: boolean
  push?: boolean
  [key: string]: boolean | undefined
}

interface NotificationProfile {
  [section: string]: {
    [itemTitle: string]: NotificationItem
  }
}
export interface NotificationPreferences {
  entityEditable: boolean
  derived: string[]
  rule: {
    profile: NotificationProfile
    entity: {
      general: {
        news: {
          mail: boolean
          push: boolean
        }
        dateBreak: {
          mail: boolean
        }
      }
      paymentStates: {
        added: {
          mail: boolean
          push: boolean
        }
        pending: {
          mail: boolean
          push: boolean
        }
        paid: {
          mail: boolean
          push: boolean
        }
      }
      paymentSignatures: {
        signatureRequest: {
          mail: boolean
          push: boolean
        }
        signature: {
          mail: boolean
          push: boolean
        }
      }
      entityProfiles: {
        added: {
          mail: boolean
          push: boolean
        }
        deleted: {
          mail: boolean
          push: boolean
        }
        roleUpdated: {
          mail: boolean
          push: boolean
        }
      }
    }
  }
}

export interface NotificationRules {
  notificationPreferences: NotificationPreferences
}

export interface Iam {
  acl: ACL
  entity: Entity
  entityId: string
  userIds: Array<string>
  profileIds: Array<string>
  entityIds: Array<string>
  entityRoles: Array<string>
}

export interface ValidateProfile {
  sessionId: string
  status: string
  userId: string
  profileId: string
  entityId: string
  dateExpire: string
  tfaMethod: string
  tfaProperties: string
}

export interface ValidateProfile {
  sessionId: string
  status: string
  userId: string
  profileId: string
  entityId: string
  dateExpire: string
  tfaMethod: string
  tfaProperties: string
}

export interface PasswordPolicyRule {
  maxLength: number
  minAlpha: number
  minLength: number
  minNumeric: number
  minSpecial: number
  rollingUpdateDays?: number
}

export interface PasswordPolicy {
  derived: string[]
  entityEditable: boolean
  rule: PasswordPolicyRule
}
export interface ClearingNetworksReferenceLengthRule {
  bg: number
  pg: number
  sepa: number
  bacs: number
  swift: number
  ach: number
  sic: number
}

export interface ClearingNetworksReferenceLength {
  entityEditable: boolean
  derived: string[]
  rule: ClearingNetworksReferenceLengthRule
}

export interface RequiredPIFields {
  remittanceAdvice: boolean
  attachment: boolean
}

export interface AvailableRoles {
  derived: string[]
  entityEditable: boolean
  rule: AvailableRolesRule
}

export interface AvailableRolesRule {
  [key: string]: boolean
}

export enum Providers {
  AIRPLUS = 'airplus',
}
