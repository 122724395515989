import { PaymentFormData, PaymentInstruction, PaymentInstructionTemplate, Recurrence } from 'types/paymentInstruction'
import { INTERVALS, mapFormToPaymentInstruction } from '.'
import dayjs from 'dayjs'
import { Providers } from 'types/rules'

export default function mapFormToPaymentInstructionTemplate(
  formData: PaymentFormData,
  profileId: string,
  recurrenceData: Recurrence,
  dateDue: dayjs.Dayjs,
  paymentInstructionData?: PaymentInstruction
): PaymentInstructionTemplate {
  const pi = mapFormToPaymentInstruction(formData, profileId, dateDue)

  if (pi.vcnAccountName) {
    delete pi.vcnAccountName
  }

  if (pi.vcnAccountNumber) {
    delete pi.vcnAccountNumber
  }

  if (pi.vcnProviderProperties) {
    delete pi.vcnProviderProperties
  }

  const intervalValue = INTERVALS[recurrenceData.interval!].value

  const template: PaymentInstructionTemplate = {
    profileId: profileId,
    occurrencesRemaining: recurrenceData.occurrencesRemaining,
    interval: {
      ...intervalValue,
      infinity: !recurrenceData.limit,
    },
    dateDueNext: dateDue.format('YYYY-MM-DD'),
    creationChannel: {
      channel: 'web',
    },
    paymentInstructionProperties: {
      ...paymentInstructionData,
      ...pi,
      creationChannel: {
        channel: 'template',
      },
    },
  }

  if (formData.vcnAccountName) {
    template.vcnAccountName = formData.vcnAccountName
  }

  if (formData.vcnAccountNumber) {
    template.vcnAccountNumber = formData.vcnAccountNumber
  }

  if (formData.vcnProviderProperties) {
    template.vcnProviderProperties = Object.entries(formData.vcnProviderProperties).map(([key, value]) => ({
      [key]: value,
    }))

    template.vcnProviderId = Providers.AIRPLUS
  }

  return template
}
