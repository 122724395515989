import { defineMessages } from 'react-intl'

export const beneficiaryStrings = defineMessages({
  'beneficiary.paymentMethod.label': {
    id: 'beneficiary.paymentMethod.label',
    defaultMessage: 'Payment method',
    description: 'Beneficiary payment method label',
  },
  'beneficiary.paymentMethod.bgPg': {
    id: 'beneficiary.paymentMethod.bgPg',
    defaultMessage: 'BG/PG (Sweden)',
    description: 'Beneficiary payment method BG/PG label',
  },
  'beneficiary.paymentMethod.bacs': {
    id: 'beneficiary.paymentMethod.bacs',
    defaultMessage: 'BACS/FP (UK)',
    description: 'Beneficiary payment method BACS label',
  },
  'beneficiary.paymentMethod.sepa': {
    id: 'beneficiary.paymentMethod.sepa',
    defaultMessage: 'SEPA (EU)',
    description: 'Beneficiary payment method SEPA label',
  },
  'beneficiary.paymentMethod.swift': {
    id: 'beneficiary.paymentMethod.swift',
    defaultMessage: 'SWIFT (International)',
    description: 'Beneficiary payment method SWIFT label',
  },
  'beneficiary.clearingNetwork.bg': {
    id: 'beneficiary.clearingNetwork.bg',
    defaultMessage: 'BankGiro (Bg)',
    description: 'Beneficiary clearing network BG label',
  },
  'beneficiary.clearingNetwork.pg': {
    id: 'beneficiary.clearingNetwork.pg',
    defaultMessage: 'Plusgiro (PG)',
    description: 'Beneficiary clearing network PG label',
  },
  'beneficiary.details.paymentMethod.swift': {
    id: 'beneficiary.details.paymentMethod.swift',
    defaultMessage: 'SWIFT/BIC code',
    description: 'Routing number SWIFT label',
  },
  'beneficiary.details.paymentMethod.bacs': {
    id: 'beneficiary.details.paymentMethod.bacs',
    defaultMessage: 'UK sort code',
    description: 'Routing number bacs label',
  },
  'beneficiary.details.paymentMethod': {
    id: 'beneficiary.details.paymentMethod',
    defaultMessage: 'Routing no.',
    description: 'Routing number default label',
  },
  'beneficiary.name': {
    id: 'beneficiary.name',
    defaultMessage: 'Name of recipient',
    description: 'This string sets beneficiary name label',
  },
  'beneficiary.table.title': {
    id: 'beneficiary.table.title',
    defaultMessage: 'Name of recipient',
    description: 'This string sets beneficiary table column name',
  },
  'beneficiary.table.currency': {
    id: 'beneficiary.table.currency',
    defaultMessage: 'Currency',
    description: 'This string sets beneficiary table currency column',
  },
  'beneficiary.name.placeholder': {
    id: 'beneficiary.name.placeholder',
    defaultMessage: 'Add name',
    description: 'This string sets beneficiary name placeholder',
  },
  'beneficiary.title': {
    id: 'beneficiary.title',
    defaultMessage: 'Recipient title',
    description: 'This string sets beneficiary title label',
  },
  'beneficiary.title.error.invalid.character': {
    id: 'beneficiary.title.error.invalid.character',
    defaultMessage: 'Invalid character input',
    description:
      "Character input is not one of the allowed characters.a-z, digits from 0-9, characters as / - ? : ( ) . , ' + and space",
  },
  'beneficiary.title.placeholder': {
    id: 'beneficiary.title.placeholder',
    defaultMessage: 'Add name',
    description: 'This string sets beneficiary title placeholder',
  },
  'beneficiary.aba.routingNumber': {
    id: 'beneficiary.aba.routingNumber',
    defaultMessage: 'Routing number',
    description: 'This string sets beneficiary aba routing number label',
  },
  'beneficiary.bicCode': {
    id: 'beneficiary.bicCode',
    defaultMessage: 'SWIFT/BIC code',
    description: 'This string sets beneficiary SWIFT/BIC code label',
  },
  'beneficiary.bicCode.placeholder': {
    id: 'beneficiary.bicCode.placeholder',
    defaultMessage: 'Add code',
    description: 'This string sets beneficiary SWIFT/BIC code placeholder',
  },
  'beneficiary.bicCode.error.invalid': {
    id: 'beneficiary.bicCode.error.invalid',
    defaultMessage: 'Please add a valid code',
    description: 'Input does not follow SWIFT/BIC standard pattern',
  },
  'beneficiary.bicCode.error.required': {
    id: 'beneficiary.bicCode.error.required',
    defaultMessage: 'Please add a valid code',
    description: 'SWIFT/BIC required error message',
  },
  'beneficiary.routingNumber': {
    id: 'beneficiary.routingNumber',
    defaultMessage: 'Routing number',
    description: 'This string sets beneficiary routing number label',
  },
  'beneficiary.routingNumber.placeholder': {
    id: 'beneficiary.routingNumber.placeholder',
    defaultMessage: 'Enter routing number',
    description: 'This string sets beneficiary routing number placeholder',
  },
  'beneficiary.routingNumber.swiftLocal': {
    id: 'beneficiary.routingNumber.swiftLocal',
    defaultMessage: 'Routing/ABA',
    description: 'This string sets beneficiary routing number label',
  },
  'beneficiary.button.getName': {
    id: 'beneficiary.button.getName',
    defaultMessage: 'Get Name',
    description: 'This string sets beneficiary get name button label',
  },
  'beneficiary.button.changeRecipient': {
    id: 'beneficiary.button.changeRecipient',
    defaultMessage: 'Change recipient',
    description: 'This string sets beneficiary change recipient button label',
  },
  'beneficiary.text.beneficiaryNotFound': {
    id: 'beneficiary.text.beneficiaryNotFound',
    defaultMessage:
      'We couldn’t find the receipient name. It’s alright, you can still make the payment and, if you like, add a recipient name below',
    description: 'This string sets beneficiary beneficiary not found text',
  },
  'beneficiary.giroNumber': {
    id: 'beneficiary.giroNumber',
    defaultMessage: 'Giro number',
    description: 'This string sets beneficiary giro number label',
  },
  'beneficiary.bank.accountNumber.invalid': {
    id: 'beneficiary.bank.accountNumber.invalid',
    defaultMessage: 'Please add a valid Bankgiro number that consists of 7 or 8 digits.',
    description: 'Beneficiary BG account number invalid',
  },
  'beneficiary.bank.giroNumber': {
    id: 'beneficiary.bank.giroNumber',
    defaultMessage: 'Bankgiro number',
    description: 'This string sets beneficiary bank giro number label',
  },
  'beneficiary.plus.accountNumber.invalid': {
    id: 'beneficiary.plus.accountNumber.invalid',
    defaultMessage: 'Please add a valid Plusgiro number that consists of 2 to 10 digits.',
    description: 'Beneficiary PG account number invalid',
  },
  'beneficiary.plus.giroNumber': {
    id: 'beneficiary.plus.giroNumber',
    defaultMessage: 'Plusgiro number',
    description: 'This string sets beneficiary plus giro number label',
  },
  'beneficiary.giroNumber.placeholder': {
    id: 'beneficiary.giroNumber.placeholder',
    defaultMessage: 'Enter giro number',
    description: 'This string sets beneficiary giro number placeholder',
  },
  'beneficiary.bank.giroNumber.placeholder': {
    id: 'beneficiary.bank.giroNumber.placeholder',
    defaultMessage: 'Enter bank giro number',
    description: 'This string sets beneficiary bank giro number placeholder',
  },
  'beneficiary.plus.giroNumber.placeholder': {
    id: 'beneficiary.plus.giroNumber.placeholder',
    defaultMessage: 'Enter plus giro number',
    description: 'This string sets beneficiary plus giro number placeholder',
  },
  'beneficiary.iban': {
    id: 'beneficiary.iban',
    defaultMessage: 'Account number (IBAN)',
    description: 'This string sets beneficiary iban label',
  },
  'beneficiary.accountNumber': {
    id: 'beneficiary.accountNumber',
    defaultMessage: 'Account number',
    description: 'This string sets beneficiary account number label',
  },
  'beneficiary.accountNumberShort': {
    id: 'beneficiary.accountNumberShort',
    defaultMessage: 'Account no.',
    description: 'This string sets beneficiary account number label',
  },
  'beneficiary.accountNumber.placeholder': {
    id: 'beneficiary.accountNumber.placeholder',
    defaultMessage: 'Add number',
    description: 'This string sets beneficiary account number placeholder',
  },
  'beneficiary.country': {
    id: 'beneficiary.country',
    defaultMessage: 'Country',
    description: 'This string sets beneficiary geography label',
  },
  'beneficiary.payoutCountry': {
    id: 'beneficiary.payoutCountry',
    defaultMessage: 'Payout country',
    description: 'This string sets recipient payout country',
  },
  'beneficiary.country.placeholder': {
    id: 'beneficiary.country.placeholder',
    defaultMessage: 'Search to select',
    description: 'This string sets beneficiary geography placeholder',
  },
  'beneficiary.countrySelect.placeholder': {
    id: 'beneficiary.countrySelect.placeholder',
    defaultMessage: 'Select country',
    description: 'This string sets beneficiary geography placeholder',
  },
  'beneficiary.type': {
    id: 'beneficiary.type',
    defaultMessage: 'Type',
    description: 'This string sets beneficiary type label',
  },
  'beneficiary.accountType': {
    id: 'beneficiary.accountType',
    defaultMessage: 'Account type',
    description: 'This string sets beneficiary account type label',
  },
  'beneficiary.currency': {
    id: 'beneficiary.currency',
    defaultMessage: 'Currency',
    description: 'This string sets beneficiary currency label',
  },
  'beneficiary.recepientType': {
    id: 'beneficiary.recepientType',
    defaultMessage: 'Type',
    description: 'This string sets beneficiary recipient type label',
  },
  'beneficiary.achAccountType': {
    id: 'beneficiary.achAccountType',
    defaultMessage: 'Account type',
    description: 'This string sets beneficiary recipient type label',
  },
  'beneficiary.accountNumberType': {
    id: 'beneficiary.accountNumberType',
    defaultMessage: 'Type of account number',
    description: 'This string sets beneficiary account number type label',
  },
  'beneficiary.IBAN': {
    id: 'beneficiary.IBAN',
    defaultMessage: 'IBAN',
    description: 'This string sets beneficiary IBAN label',
  },
  'beneficiary.BBAN': {
    id: 'beneficiary.BBAN',
    defaultMessage: 'Local (BBAN)',
    description: 'This string sets beneficiary BBAN label',
  },
  'beneficiary.sortCode': {
    id: 'beneficiary.sortCode',
    defaultMessage: 'Sort code',
    description: 'This string sets beneficiary sort code label',
  },
  'beneficiary.ukSortCode': {
    id: 'beneficiary.ukSortCode',
    defaultMessage: 'UK Sort code',
    description: 'This string sets beneficiary uk sort code label',
  },
  'beneficiary.sortCode.placeholder': {
    id: 'beneficiary.sortCode.placeholder',
    defaultMessage: 'Add code',
    description: 'This string sets beneficiary sort code placeholder',
  },
  'beneficiary.orgNumber': {
    id: 'beneficiary.orgNumber',
    defaultMessage: 'Organisation Number (optional)',
    description: 'This string sets beneficiary organization number label',
  },
  'beneficiary.orgNumber.placeholder': {
    id: 'beneficiary.orgNumber.placeholder',
    defaultMessage: 'Enter company number',
    description: 'This string sets beneficiary organization number placeholder',
  },
  'beneficiary.email': {
    id: 'beneficiary.email',
    defaultMessage: 'E-mail address',
    description: 'This string sets beneficiary email address label',
  },
  'beneficiary.phoneNumber': {
    id: 'beneficiary.phoneNumber',
    defaultMessage: 'Phone number',
    description: 'This string sets beneficiary phone number label',
  },
  'beneficiary.streetAddress': {
    id: 'beneficiary.streetAddress',
    defaultMessage: 'Address',
    description: 'This string sets beneficiary street address label',
  },
  'beneficiary.streetAddress.placeholder': {
    id: 'beneficiary.streetAddress.placeholder',
    defaultMessage: 'Enter recipient address',
    description: 'This string sets beneficiary street address placeholder',
  },
  'beneficiary.zipCode': {
    id: 'beneficiary.zipCode',
    defaultMessage: 'Recipient zip/postal code',
    description: 'This string sets beneficiary zip code label',
  },
  'beneficiary.zipCode.placeholder': {
    id: 'beneficiary.zipCode.placeholder',
    defaultMessage: 'Enter recipient zip/postal code',
    description: 'This string sets beneficiary zip code placeholder',
  },
  'beneficiary.city': {
    id: 'beneficiary.city',
    defaultMessage: 'Recipient city',
    description: 'This string sets beneficiary city label',
  },
  'beneficiary.state': {
    id: 'beneficiary.state',
    defaultMessage: 'State',
    description: 'This string sets beneficiary city label',
  },
  'beneficiary.city.placeholder': {
    id: 'beneficiary.city.placeholder',
    defaultMessage: 'Recipient city',
    description: 'This string sets beneficiary city placeholder',
  },
  'beneficiary.actions': {
    id: 'beneficiary.actions',
    defaultMessage: 'Actions',
    description: 'Action label for beneficiary table',
  },
  'beneficiary.slider.label.add': {
    id: 'beneficiary.slider.label.add',
    defaultMessage: 'Add beneficiary',
    description: 'Right slider label for adding new beneficiary',
  },
  'beneficiary.slider.label.details': {
    id: 'beneficiary.slider.label.details',
    defaultMessage: 'Beneficiary details',
    description: 'Right slider label for details of a beneficiary',
  },
  'beneficiary.slider.label.edit': {
    id: 'beneficiary.slider.label.edit',
    defaultMessage: 'Edit beneficiary',
    description: 'Right slider label for editing a beneficiary',
  },
  'beneficiary.supplierFunded.label': {
    id: 'beneficiary.supplierFunded.label',
    defaultMessage: 'Supplier funded',
    description: 'Label for toggle supplier funded in beneficiary form',
  },
  'beneficiary.supplierFunded.option.yes': {
    id: 'beneficiary.supplierFunded.option.yes',
    defaultMessage: 'Yes',
    description: 'Toggle supplier funded option yes',
  },
  'beneficiary.supplierFunded.option.no': {
    id: 'beneficiary.supplierFunded.option.no',
    defaultMessage: 'No',
    description: 'Toggle supplier funded option no',
  },
  'beneficiary.supplierFunded.feePercent.label': {
    id: 'beneficiary.supplierFunded.feePercent.label',
    defaultMessage: 'Negotiated percent fee',
    description: 'The supplier funded fee percent label',
  },
  'beneficiary.supplierFunded.feePercent.error.required': {
    id: 'beneficiary.supplierFunded.feePercent.error.required',
    defaultMessage: 'Percent fee can not be 0 if fixed fee is not entered',
    description: 'The supplier funded fee percent required error message',
  },
  'beneficiary.supplierFunded.feeFixed.label': {
    id: 'beneficiary.supplierFunded.feeFixed.label',
    defaultMessage: 'Negotiated fixed fee per payment',
    description: 'The supplier funded fee fixed label',
  },
  'beneficiary.supplierFunded.feeFixed.error.required': {
    id: 'beneficiary.supplierFunded.feeFixed.error.required',
    defaultMessage: 'Fixed fee can not be 0 if percent fee is not entered',
    description: 'The supplier funded fee fixed required error message',
  },
  'beneficiary.supplierFunded.defaultCard.label': {
    id: 'beneficiary.supplierFunded.defaultCard.label',
    defaultMessage: 'Default payment card',
    description: 'The supplier funded default payment card label',
  },
  'beneficiary.not.added': {
    id: 'beneficiary.not.added',
    defaultMessage: 'No beneficiaries added',
    description: 'No beneficiaries added message',
  },
  'beneficiary.not.found': {
    id: 'beneficiary.not.found',
    defaultMessage: 'No beneficiaries found',
    description: 'No beneficiaries found message',
  },
  'beneficiary.edit.btn': {
    id: 'beneficiary.edit.btn',
    defaultMessage: 'Edit',
    description: 'Beneficiaries drawer edit button',
  },
  'beneficiary.delete.btn': {
    id: 'beneficiary.delete.btn',
    defaultMessage: 'Delete',
    description: 'Beneficiaries drawer delete button',
  },
  'beneficiary.bgpg.button.lookup': {
    id: 'beneficiary.bgpg.button.lookup',
    defaultMessage: 'Find recipient',
    description: 'Beneficiary BG/PG lookup button label',
  },
  'beneficiary.bgpg.title.placeholder': {
    id: 'beneficiary.bgpg.title.placeholder',
    defaultMessage: 'Add name',
    description: 'Beneficiary BG/PG title placeholder',
  },
  'beneficiary.bgpg.lookup.failed': {
    id: 'beneficiary.bgpg.lookup.failed',
    defaultMessage:
      "We're unable to find your recipient. Don't worry, you can still add your recipient's name manually if all the information is correct.",
    description: 'Beneficiary BG/PG lookup failed',
  },
  'beneficiary.bgpg.title.label': {
    id: 'beneficiary.bgpg.title.label',
    defaultMessage: 'Name of recipient',
    description: 'Beneficiary BG/PG account number invalid',
  },
  'beneficiary.bgpg.title.invalid': {
    id: 'beneficiary.bgpg.title.invalid',
    defaultMessage: 'Please add the name of the recipient.',
    description: 'Beneficiary BG/PG title invalid',
  },
  'beneficiary.paymentmethodLabel.bg': {
    id: 'beneficiary.paymentmethodLabel.bg',
    defaultMessage: 'Bankgiro (BG)',
    description: 'Bankgiro payment type label',
  },
  'beneficiary.paymentmethodLabel.pg': {
    id: 'beneficiary.paymentmethodLabel.pg',
    defaultMessage: 'Plusgiro (PG)',
    description: 'Plusgiro payment type label',
  },
  'beneficiary.paymentmethodLabel.iban': {
    id: 'beneficiary.paymentmethodLabel.iban',
    defaultMessage: 'IBAN (International)',
    description: 'IBAN payment type label',
  },
  'beneficiary.paymentmethodLabel.local_gb': {
    id: 'beneficiary.paymentmethodLabel.local_gb',
    defaultMessage: 'Local bank account',
    description: 'Local payment type label for GB',
  },
  'beneficiary.paymentmethodLabel.swift': {
    id: 'beneficiary.paymentmethodLabel.swift',
    defaultMessage: 'SWIFT/BIC code (International)',
    description: 'Swift payment type label',
  },
  'beneficiary.paymentmethodLabel.local_us': {
    id: 'beneficiary.paymentmethodLabel.local_us',
    defaultMessage: 'ACH (USA)',
    description: 'ACH payment type label for US',
  },
  'beneficiary.paymentmethodLabel.aba': {
    id: 'beneficiary.paymentmethodLabel.aba',
    defaultMessage: 'ABA (Local US)',
    description: 'ABA payment type label for US',
  },
  'beneficiary.currency.label': {
    id: 'beneficiary.currency.label',
    defaultMessage: 'Currency',
    description: 'Label for currency dropdown',
  },
  'beneficiary.currency.aed': {
    id: 'beneficiary.currency.aed',
    defaultMessage: 'Arabic Emirates Dirhams (AED)',
    description: 'AED currency in dropdown',
  },
  'beneficiary.currency.sgd': {
    id: 'beneficiary.currency.sgd',
    defaultMessage: 'Singaporean Dollars (SGD)',
    description: 'SGD currency in dropdown',
  },
  'beneficiary.currency.gbp': {
    id: 'beneficiary.currency.gbp',
    defaultMessage: 'British Pounds (GBP)',
    description: 'GBP currency in dropdown',
  },
  'beneficiary.currency.czk': {
    id: 'beneficiary.currency.czk',
    defaultMessage: 'Czech Koruna (CZK)',
    description: 'CZK currency in dropdown',
  },
  'beneficiary.currency.cad': {
    id: 'beneficiary.currency.cad',
    defaultMessage: 'Canadian Dollar (CAD)',
    description: 'CAD currency in dropdown',
  },
  'beneficiary.currency.eur': {
    id: 'beneficiary.currency.eur',
    defaultMessage: 'Euro (EUR)',
    description: 'EUR currency in dropdown',
  },
  'beneficiary.currency.huf': {
    id: 'beneficiary.currency.huf',
    defaultMessage: 'Hungarian Forint (HUF)',
    description: 'HUF currency in dropdown',
  },
  'beneficiary.currency.ils': {
    id: 'beneficiary.currency.ils',
    defaultMessage: 'Israeli New Shekel (ILS)',
    description: 'ILS currency in dropdown',
  },
  'beneficiary.currency.pln': {
    id: 'beneficiary.currency.pln',
    defaultMessage: 'Polish Zloty (PLN)',
    description: 'PLN currency in dropdown',
  },
  'beneficiary.currency.sek': {
    id: 'beneficiary.currency.sek',
    defaultMessage: 'Swedish Krona (SEK)',
    description: 'SEK currency in dropdown',
  },
  'beneficiary.currency.chf': {
    id: 'beneficiary.currency.chf',
    defaultMessage: 'Swiss Franc (CHF)',
    description: 'CHF currency in dropdown',
  },
  'beneficiary.currency.try': {
    id: 'beneficiary.currency.try',
    defaultMessage: 'Turkish Lira (TRY)',
    description: 'TRY currency in dropdown',
  },
  'beneficiary.currency.usd': {
    id: 'beneficiary.currency.usd',
    defaultMessage: 'United States Dollar (USD)',
    description: 'USD currency in dropdown',
  },
  'beneficiary.currency.dkk': {
    id: 'beneficiary.currency.dkk',
    defaultMessage: 'Danish Krone (DKK)',
    description: 'DKK currency in dropdown',
  },
  'beneficiary.currency.nok': {
    id: 'beneficiary.currency.nok',
    defaultMessage: 'Norwegian Krone (NOK)',
    description: 'NOK currency in dropdown',
  },
  'beneficiary.drawer.submit.btn.label': {
    id: 'beneficiary.drawer.submit.btn.label',
    defaultMessage: 'Add recipient',
    description: 'Beneficiary drawer submit button label',
  },
  'beneficiary.drawer.paymentMethodSelector.less.label': {
    id: 'beneficiary.drawer.paymentMethodSelector.less.label',
    defaultMessage: 'Less',
    description: 'Beneficiary drawer payment method selector options less label',
  },
  'beneficiary.drawer.paymentMethodSelector.more.label': {
    id: 'beneficiary.drawer.paymentMethodSelector.more.label',
    defaultMessage: 'More',
    description: 'Beneficiary drawer payment method selector options more label',
  },
  'beneficiary.form.address.street.label': {
    id: 'beneficiary.form.address.street.label',
    defaultMessage: 'Recipient street address and house number',
    description: 'Beneficiary form street label',
  },
  'beneficiary.form.address.street.placeholder': {
    id: 'beneficiary.form.address.street.placeholder',
    defaultMessage: 'Add street and house number',
    description: 'Beneficiary form street placeholder',
  },
  'beneficiary.form.address.street.error': {
    id: 'beneficiary.form.address.street.error',
    defaultMessage: 'Please add the street address and house number of the recipient.',
    description: 'Beneficiary form street error message',
  },
  'beneficiary.form.address.city.label': {
    id: 'beneficiary.form.address.city.label',
    defaultMessage: 'City',
    description: 'Beneficiary form city label',
  },
  'beneficiary.form.address.city.placeholder': {
    id: 'beneficiary.form.address.city.placeholder',
    defaultMessage: 'Add city',
    description: 'Beneficiary form city placeholder',
  },
  'beneficiary.form.address.city.error': {
    id: 'beneficiary.form.address.city.error',
    defaultMessage: 'Please add the city of the recipient.',
    description: 'Beneficiary form city error message',
  },
  'beneficiary.form.address.zip.label': {
    id: 'beneficiary.form.address.zip.label',
    defaultMessage: 'Zip code',
    description: 'Beneficiary form zip label',
  },
  'beneficiary.form.address.zip.placeholder': {
    id: 'beneficiary.form.address.zip.placeholder',
    defaultMessage: 'Add zip code',
    description: 'Beneficiary form zip placeholder',
  },
  'beneficiary.form.address.zip.error': {
    id: 'beneficiary.form.address.zip.error',
    defaultMessage: 'Please add the zip code of the recipient.',
    description: 'Beneficiary form zip code error message',
  },
  'beneficiary.form.address.state.label': {
    id: 'beneficiary.form.address.state.label',
    defaultMessage: 'State',
    description: 'Beneficiary form zip label',
  },
  'beneficiary.form.address.state.placeholder': {
    id: 'beneficiary.form.address.state.placeholder',
    defaultMessage: 'Add state',
    description: 'Beneficiary form state placeholder',
  },
  'beneficiary.form.address.state.error': {
    id: 'beneficiary.form.address.state.error',
    defaultMessage: 'Please add the state of the recipient.',
    description: 'Beneficiary form state code error message',
  },
  'beneficiary.form.ach.routing.error': {
    id: 'beneficiary.form.routing.error',
    defaultMessage: 'Please add a valid number.',
    description: 'Beneficiary form routing number error message',
  },
  'beneficiary.form.ach.account.error': {
    id: 'beneficiary.form.account.error',
    defaultMessage: 'Please add a valid number.',
    description: 'Beneficiary form routing number error message',
  },
  'beneficiary.form.address.error': {
    id: 'beneficiary.form.address.error',
    defaultMessage: 'Please enter a valid address. ',
    description: 'Beneficiary form address error message',
  },
  'beneficiary.form.address.error.invalidStart': {
    id: 'beneficiary.form.address.error.invalidStart',
    defaultMessage: "The address cannot start with spaces or the characters '-:'.",
    description: 'Error message for address starting with invalid characters',
  },
  'beneficiary.form.address.error.invalidTitleCity': {
    id: 'beneficiary.form.address.error.invalidTitleCity',
    defaultMessage: "The city name cannot start with spaces or the characters '-:'.",
    description: 'Error message for city name starting with invalid characters',
  },
  'beneficiary.form.address.error.invalidTitleZip': {
    id: 'beneficiary.form.address.error.invalidTitleZip',
    defaultMessage: "The ZIP code cannot start with spaces or the characters '-:'.",
    description: 'Error message for ZIP code starting with invalid characters',
  },
  'beneficiary.details.name': {
    id: 'beneficiary.details.name',
    defaultMessage: 'Name',
    description: 'Beneficiary details name',
  },
  'beneficiary.details.country': {
    id: 'beneficiary.details.country',
    defaultMessage: 'Country',
    description: 'Beneficiary details country',
  },
  'beneficiary.details.currency': {
    id: 'beneficiary.details.currency',
    defaultMessage: 'Currency',
    description: 'Beneficiary details currency',
  },
  'beneficiary.details.type': {
    id: 'beneficiary.details.type',
    defaultMessage: 'Type',
    description: 'Beneficiary details type',
  },
  'beneficiary.form.ach.careof.label': {
    id: 'beneficiary.form.ach.careof.label',
    defaultMessage: 'C/O.',
    description: 'Beneficiary form care of label',
  },
  'beneficiary.form.ach.careof.placeholder': {
    id: 'beneficiary.form.ach.careof.placeholder',
    defaultMessage: 'Add care of',
    description: 'Beneficiary form care of placeholder',
  },
  'beneficiary.form.ach.careof.button': {
    id: 'beneficiary.form.ach.careof.button',
    defaultMessage: 'Add C/O',
    description: 'Beneficiary form care of button',
  },
  'beneficiary.form.ach.apartment.label.placeholder': {
    id: 'beneficiary.form.ach.apartment.label.placeholder',
    defaultMessage: 'Apartment, suite, building, etc.',
    description: 'Beneficiary form apartment, etc. label and placeholder',
  },
  'beneficiary.details.title.error.message': {
    id: 'beneficiary.details.title.error.message',
    defaultMessage: 'Invalid name',
    description: 'Beneficiary details title error message',
  },
  'beneficiary.details.title.error.description': {
    id: 'beneficiary.details.title.error.description',
    defaultMessage:
      "You are allowed up to 35 characters that include lower and uppercase letters and numbers between 0-9, space and the following characters: / - ? : ( ) . , ' +. ",
    description: 'Beneficiary details title error description',
  },
  'beneficiary.details.title.not_changed': {
    id: 'beneficiary.details.title.not_changed',
    defaultMessage: 'The name of the recipient was not changed.',
    description: 'Beneficiary details title not changed',
  },
  'beneficiary.swift.country.mismatch.title': {
    id: 'beneficiary.swift.country.mismatch.title',
    defaultMessage: 'Account mismatch',
    description: 'Beneficiary SWIFT country mismatch',
  },
  'beneficiary.swift.country.mismatch.description': {
    id: 'beneficiary.swift.country.mismatch.description',
    defaultMessage:
      'The SWIFT/BIC code and the Account number are not a valid combination. Please adjust where needed to add this recipient.',
    description: 'Beneficiary SWIFT country mismatch',
  },
})
