import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import Payments from '../../components/Payments/Payments'
import PaymentsSummary from '../../components/PaymentsSummary/PaymentsSummary'
import SubmitPayments from '../../components/SubmitPayments/SubmitPayments'
import ForeignCurrencyWarning from 'components/ForeignCurrencyWarning/ForeignCurrencyWarning'
import { Set, PAYMENT_KIND, PAYMENT_STATE, SetTotalDetails } from 'types/paymentInstruction'
import { pi } from 'lang/definitions'
import PaymentsHeader from '../../components/PaymentsHeader'
import Approvers from '../../Add/ReviewPayments/Approvers'
import { Approver } from 'types/user'
import { Checkbox } from 'antd'
import { useCurrentPayment } from 'stores/Payment'
import useReviewPaymentsStyle from '../../Add/ReviewPayments/ReviewPayments.style'
import { getPaymentDetailsByKind } from 'pages/PaymentInstructions/utils'

interface ReviewPaymentsProps {
  approvers: Approver[]
  totalNumberOfPayments: number
  numberOfSignaturesRequired: number
  removePaymentFromSet: (kind: PAYMENT_KIND, id: string) => void
  showPaymentDetails: (kind: PAYMENT_KIND, paymentId: string) => void
  getNumberOfSignaturesRequired: (paymentIds: string[]) => void
  goToProgressSlide: () => void
  submitButton?: React.JSX.Element
  startPolling: (set: Set) => void
  requireActionSet?: Set
}

const ReviewPayments = (props: ReviewPaymentsProps): React.JSX.Element => {
  const {
    totalNumberOfPayments,
    approvers,
    removePaymentFromSet,
    showPaymentDetails,
    getNumberOfSignaturesRequired,
    goToProgressSlide,
    submitButton,
    requireActionSet,
    startPolling,
  } = props

  const intl = useIntl()
  const { styles } = useReviewPaymentsStyle()

  const {
    state: { signatureOrder, needConfirmation, checkedConfirmation, paymentsInSet, approveAndPaySet, approveSet },
    actions: { setCheckedConfirmation, setNeedConfirmation },
  } = useCurrentPayment()

  const handlePaymentDetailsForRequiredSignaturerSet = (
    kind: 'paymentInstruction' | 'paymentInstructionTemplate',
    set: Set | undefined
  ): SetTotalDetails | undefined => {
    return set?.paymentInstructionState === PAYMENT_STATE.REQUIRE_SIGNATURE
      ? getPaymentDetailsByKind(kind, set)
      : undefined
  }

  const paymentInstructionDetailsToPay = handlePaymentDetailsForRequiredSignaturerSet(
    'paymentInstruction',
    approveAndPaySet
  )

  const templateDetailsToPay = handlePaymentDetailsForRequiredSignaturerSet(
    'paymentInstructionTemplate',
    approveAndPaySet
  )

  const paymentInstructionDetailsToApprove = handlePaymentDetailsForRequiredSignaturerSet(
    'paymentInstruction',
    approveSet
  )

  const templateDetailsToApprove = handlePaymentDetailsForRequiredSignaturerSet(
    'paymentInstructionTemplate',
    approveSet
  )

  const flowIsSequential = signatureOrder === 'sequential'

  const multiplePaymentsNeedConfirmation = paymentsInSet?.filter((pi) => pi.newBeneficiary).length > 1

  const currentRound = paymentsInSet.length ? (paymentsInSet[0].signatures?.signatureRequests.length || 0) + 1 : 0

  useEffect(() => {
    if (!approveSet) {
      return
    }

    const paymentInstructionIds = approveSet.paymentInstructionIds || []
    const paymentInstructionTemplateIds = approveSet.paymentInstructionTemplateIds || []
    getNumberOfSignaturesRequired([...paymentInstructionIds, ...paymentInstructionTemplateIds])
  }, [approveSet])

  useEffect(() => {
    handleDisplayWarning()
  }, [paymentsInSet])

  const handleDisplayWarning = (): void => {
    if (!paymentsInSet) {
      return
    }

    const shouldShowWarning = paymentsInSet.some((payment) => payment.newBeneficiary === true)
    setNeedConfirmation(shouldShowWarning)
  }

  const onChangeCheckBox = (value: boolean) => {
    setCheckedConfirmation(value)
  }

  return (
    <div className={styles.reviewContainer} data-testid="review-payments-container">
      <PaymentsHeader label={intl.formatMessage(pi['pi.review.reviewPayments.header'])} enablePaymentsFilter={false} />
      {paymentsInSet.length ? (
        <>
          <Payments
            paymentList={paymentsInSet}
            totalNumberOfPayments={totalNumberOfPayments}
            disableSelect={true}
            selectPayments={() => {
              return
            }}
            deselectPayments={() => {
              return
            }}
            removePaymentFromSet={removePaymentFromSet}
            showPaymentDetails={showPaymentDetails}
          />
          <>
            {paymentInstructionDetailsToPay && paymentInstructionDetailsToPay.count > 0 && (
              <PaymentsSummary
                title="pi.add.reviewPayments.paymentSummary.header.pi"
                setTotalDetails={paymentInstructionDetailsToPay}
              />
            )}
            {templateDetailsToPay && templateDetailsToPay.count > 0 && (
              <PaymentsSummary
                title="pi.add.reviewPayments.paymentSummary.header.template"
                setTotalDetails={templateDetailsToPay}
              />
            )}
            {paymentInstructionDetailsToApprove && paymentInstructionDetailsToApprove.count > 0 && (
              <PaymentsSummary
                title="pi.add.reviewPayments.paymentSummary.header.pi.approver"
                setTotalDetails={paymentInstructionDetailsToApprove}
              />
            )}
            {templateDetailsToApprove && templateDetailsToApprove.count > 0 && (
              <PaymentsSummary
                title="pi.add.reviewPayments.paymentSummary.header.template.approver"
                setTotalDetails={templateDetailsToApprove}
              />
            )}
            {needConfirmation && (
              <div className="confirm-checkbox">
                <Checkbox
                  checked={checkedConfirmation}
                  onChange={(e) => {
                    onChangeCheckBox(e.target.checked)
                  }}
                >
                  {multiplePaymentsNeedConfirmation
                    ? intl.formatMessage(pi['pi.multipleNewBeneficiaryConfirmation.message'])
                    : intl.formatMessage(pi['pi.newBeneficiaryConfirmation.message'])}
                </Checkbox>
              </div>
            )}
            <ForeignCurrencyWarning payments={paymentsInSet} />
            {flowIsSequential && (
              <Approvers
                set={approveSet}
                approvers={approvers}
                getNumberOfSignaturesRequired={getNumberOfSignaturesRequired}
                currentRound={currentRound}
              />
            )}
          </>
        </>
      ) : (
        <div className={styles.noDataMessage} data-testid="no-data-message">
          <span>{intl.formatMessage(pi['pi.review.reviewPayments.noDataMessage'])}</span>
        </div>
      )}
      {submitButton ? (
        submitButton
      ) : (
        <SubmitPayments
          goToProgressSlide={goToProgressSlide}
          requireActionSet={requireActionSet}
          startPolling={startPolling}
          skipApproverSelection={true}
        />
      )}
    </div>
  )
}

export default ReviewPayments
