import React from 'react'
import { WarningOutlined } from '@ant-design/icons'
import { get } from 'lodash'
import { IntlShape } from 'react-intl'
import Currency from '../../components/Format/Currency/Currency'
import Date from '../../components/Format/Date/Date'
import { pi } from '../../lang/definitions'
import AccountNumber from '../../components/Format/AccountNumber/AccountNumber'
import { Tag } from 'antd'
import { Set, SetTotalDetails } from 'types/paymentInstruction'

export const columnsPayments = (language: string, intl: IntlShape) => [
  {
    title: 'Status',
    dataIndex: 'state',
    width: 150,
    render: (state: string) => {
      let statusColor
      let textColor
      let translatedState
      switch (state) {
        case 'ADDED':
          statusColor = '#5f9cce'
          textColor = 'white'
          translatedState = intl.formatMessage(pi['pi.status.added'])
          break
        case 'READY':
          statusColor = '#E2EEF9'
          textColor = 'white'
          translatedState = intl.formatMessage(pi['pi.status.ready'])
          break
        case 'REQUIRE_SIGNATURE':
          statusColor = 'rgba(255, 193, 7, 0.3)' //IE11 supported
          translatedState = intl.formatMessage(pi['pi.status.requireSignature'])
          break
        case 'PROCESSING':
          statusColor = '#46004a'
          textColor = 'white'
          translatedState = intl.formatMessage(pi['pi.status.processing'])
          break
        case 'PENDING':
          statusColor = '#FCEAC7' //IE11 supported
          translatedState = intl.formatMessage(pi['pi.status.pending'])
          break
        case 'PENDING_AUTHORIZATION':
          statusColor = 'rgba(143, 34, 58, 0.2)' //IE11 supported
          translatedState = intl.formatMessage(pi['pi.status.pending.authorization'])
          break
        case 'BANK':
          statusColor = 'rgba(248, 179, 25, 0.2)' //IE11 supported
          translatedState = intl.formatMessage(pi['pi.status.bank'])
          break
        case 'PAID':
          statusColor = '#9FCBB2'
          translatedState = intl.formatMessage(pi['pi.status.paid'])
          break
        case 'ERROR':
          statusColor = '#E49999' // red
          translatedState = intl.formatMessage(pi['pi.status.inReview'])
          break
        case 'DELETED':
          statusColor = '#f2f2f2' //IE11 supported
          translatedState = intl.formatMessage(pi['pi.status.deleted'])
          break
        case 'CANCELED':
          statusColor = '#999999'
          translatedState = intl.formatMessage(pi['pi.status.canceled'])
          break
        default:
          statusColor = '#f2f2f2'
          translatedState = ''
          break
      }

      return (
        <React.Fragment>
          <div
            className="state-status desktop-display"
            style={{
              background: statusColor,
              color: textColor ? textColor : 'inherit',
              fontWeight: 'bold',
              fontSize: '85%',
            }}
          >
            {translatedState.toLowerCase()}{' '}
            {state === 'ERROR' && <WarningOutlined style={{ color: '#8F223A', paddingLeft: 5 }} />}
          </div>
          <div
            className="mobile-status mobile-display"
            style={{
              backgroundColor: statusColor,
              height: '40px',
              width: '10px',
              borderRadius: '8px',
              fontWeight: 'bold',
              fontSize: '85%',
            }}
          ></div>
        </React.Fragment>
      )
    },
  },
  {
    title: intl.formatMessage(pi['pi.beneficiary.title']),
    dataIndex: '',
    ellipsis: true,
    className: 'beneficiary-title',
    render: (data: {
      creationChannel?: {
        channel: string
        value?: string
      }
      beneficiary: {
        title: string
        routingNumber: string | number
        accountNumber: string
        clearingNetwork: string
      }
    }) => {
      return (
        <div>
          {data.creationChannel?.channel === 'integration' && data.creationChannel.value && (
            <div>
              <Tag color="#fff">{data.creationChannel.value}</Tag>
            </div>
          )}
          <div
            style={{
              fontSize: '.9rem',
              color: 'rgba(0, 0, 0, 0.85)',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {get(
              data,
              'beneficiary.title',
              get(data, 'beneficiary.routingNumber') || get(data, 'beneficiary.accountNumber')
            )}
          </div>
          <div
            style={{
              fontSize: '.8rem',
              color: 'rgba(0, 0, 0, 0.45)',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            <AccountNumber
              accountNumber={data.beneficiary.accountNumber}
              routingNumber={data.beneficiary.routingNumber?.toString()}
              clearingNetwork={data.beneficiary.clearingNetwork}
            ></AccountNumber>
          </div>
        </div>
      )
    },
  },
  {
    title: intl.formatMessage(pi['pi.reference']),
    dataIndex: 'reference',
    className: 'desktop-display',
  },
  {
    title: intl.formatMessage(pi['pi.dateDue']),
    dataIndex: '',
    sortName: 'dateDue',
    align: 'right' as const,
    key: 'dateDue',
    className: 'date-due',
    sorter: true,
    render: (data: { dateDue: string; dateDueNext: string }) => {
      return <Date value={data.dateDue || data.dateDueNext} locale={language} time={false} />
    },
  },
  {
    title: intl.formatMessage(pi['pi.amount']),
    dataIndex: '',
    sortName: 'amount',
    align: 'right' as const,
    key: 'amount',
    sorter: true,
    className: 'amount',
    render: (data: { amount: number; currency: string }) => {
      return (
        <span style={{ fontSize: 16 }}>
          <Currency value={data.amount.toString()} locale={language} currency={data.currency} />
        </span>
      )
    },
  },
]

export const getStateTranslation = (state: string, intl: IntlShape): string => {
  let translatedState
  switch (state) {
    case 'ADDED':
      translatedState = intl.formatMessage(pi['pi.status.added'])
      break
    case 'READY':
      translatedState = intl.formatMessage(pi['pi.status.ready'])
      break
    case 'REQUIRE_SIGNATURE':
      translatedState = intl.formatMessage(pi['pi.status.requireSignature'])
      break
    case 'ACTIVE':
      translatedState = 'Active'
      break
    case 'PROCESSING':
      translatedState = intl.formatMessage(pi['pi.status.processing'])
      break
    case 'PENDING':
      translatedState = intl.formatMessage(pi['pi.status.pending'])
      break
    case 'PENDING_AUTHORIZATION':
      translatedState = intl.formatMessage(pi['pi.status.pending.authorization'])
      break
    case 'BANK':
      translatedState = intl.formatMessage(pi['pi.status.bank'])
      break
    case 'PAID':
      translatedState = intl.formatMessage(pi['pi.status.paid'])
      break
    case 'ERROR':
      translatedState = intl.formatMessage(pi['pi.status.inReview'])
      break
    case 'DELETED':
      translatedState = intl.formatMessage(pi['pi.status.deleted'])
      break
    case 'CANCELED':
      translatedState = intl.formatMessage(pi['pi.status.canceled'])
      break
    default:
      translatedState = ''
      break
  }

  return translatedState.charAt(0).toUpperCase() + translatedState.slice(1).toLowerCase()
}

export const getPaymentDetailsByKind = (
  kind: 'paymentInstruction' | 'paymentInstructionTemplate',
  set: Set | undefined
): SetTotalDetails | undefined => {
  const details = set?.detailsTotal?.details.find((details: SetTotalDetails) => details.kind === kind)
  return details
}
