import { useIntl } from 'react-intl'
import ForeignCurrencyWarning from 'components/ForeignCurrencyWarning/ForeignCurrencyWarning'
import { Set, SetTotalDetails, PAYMENT_KIND, PAYMENT_STATE } from 'types/paymentInstruction'
import { pi } from 'lang/definitions'
import { useCurrentPayment } from 'stores/Payment'
import Payments from '../../components/Payments/Payments'
import PaymentsSummary from '../../components/PaymentsSummary/PaymentsSummary'
import PaymentsHeader from '../../components/PaymentsHeader'
import useReviewPaymentsStyle from '../../Add/ReviewPayments/ReviewPayments.style'
import { getPaymentDetailsByKind } from 'pages/PaymentInstructions/utils'

interface ReviewPaymentsProps {
  set?: Set
  removePaymentFromSet: (kind: PAYMENT_KIND, id: string) => void
  showPaymentDetails: (kind: PAYMENT_KIND, paymentId: string) => void
  submitButton?: React.JSX.Element
}
const ReviewPayments = (props: ReviewPaymentsProps): React.JSX.Element => {
  const { set, removePaymentFromSet, showPaymentDetails, submitButton } = props

  const intl = useIntl()
  const { styles } = useReviewPaymentsStyle()

  const {
    state: { paymentsInSet },
  } = useCurrentPayment()

  const handlePaymentDetailsForReadySet = (
    kind: 'paymentInstruction' | 'paymentInstructionTemplate',
    set: Set | undefined
  ): SetTotalDetails | undefined => {
    return set?.paymentInstructionState === PAYMENT_STATE.READY ? getPaymentDetailsByKind(kind, set) : undefined
  }

  const paymentInstructionDetailsToPay = handlePaymentDetailsForReadySet('paymentInstruction', set)
  const templateDetailsToPay = handlePaymentDetailsForReadySet('paymentInstructionTemplate', set)

  return (
    <div className={styles.reviewContainer} data-testid="review-payments-container">
      <PaymentsHeader label={intl.formatMessage(pi['pi.review.reviewPayments.header'])} enablePaymentsFilter={false} />
      {paymentsInSet.length ? (
        <>
          <Payments
            paymentList={paymentsInSet}
            totalNumberOfPayments={paymentsInSet.length}
            disableSelect={true}
            selectPayments={() => {
              return
            }}
            deselectPayments={() => {
              return
            }}
            removePaymentFromSet={removePaymentFromSet}
            showPaymentDetails={showPaymentDetails}
          />
          <>
            {paymentInstructionDetailsToPay && paymentInstructionDetailsToPay.count > 0 && (
              <PaymentsSummary
                title="pi.add.reviewPayments.paymentSummary.header.pi"
                setTotalDetails={paymentInstructionDetailsToPay}
              />
            )}
            {templateDetailsToPay && templateDetailsToPay.count > 0 && (
              <PaymentsSummary
                title="pi.add.reviewPayments.paymentSummary.header.template"
                setTotalDetails={templateDetailsToPay}
              />
            )}
          </>
          <ForeignCurrencyWarning payments={paymentsInSet} />

          {submitButton ? submitButton : null}
        </>
      ) : (
        <div className={styles.noDataMessage} data-testid="no-data-message">
          <span>{intl.formatMessage(pi['pi.pay.reviewPayments.noDataMessage'])}</span>
        </div>
      )}
    </div>
  )
}

export default ReviewPayments
